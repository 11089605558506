@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-ExtraBold.otf");
}

@font-face {
  font-family: "Montserrat_reg";
  src: url("./fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat_semi";
  src: url("./fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Peace";
  src: url("./fonts/Peace_Sans.ttf");
}

.underline {
  text-decoration: none;
  color: black;
  font-family: "FredokaOne";
}

.body {
  background-image: url("images/bg.png");
  background-size: cover;
  background-attachment: fixed;
  width:100%;
  height: fit-content;
}
/*----------------Footer-------------*/
.footerBg{
  background-image: url('images/footer.png');
  background-size: cover;
  width:100%;
  height: 70vh;
  margin-top:50px;
}

/*----------------Events-------------*/



.card {
  width: 270px !important;
  height: 400px;
  border-radius: 6.1rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: center;
  background-color: #E0D3C0 !important;
  border: none !important
}

.card-img {
  width: 140% !important;
  align-self: center;
}

.cardText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Montserrat_reg';
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.9em;
}

.button-event {
  display: block;
  margin: auto;
  width: 115px !important;
  border-radius: 1.5em !important;
  font-size: 1em !important;
  font-family: 'Montserrat';
  background-color: #3A4741 !important
}


/*----------------CONCEPT-------------*/

.body-concept{
  background-image: url("images/bg2.png");
  background-size: cover;
  background-attachment: fixed;
  height:100vh;
}
.title-concept {
  color: #53566D !important;
  font-family: 'Peace' !important;
  font-size: 3em !important;
  text-shadow: 5px 5px #C0C9D1 !important;
  margin-bottom: 50px;
}

.button-concept {
  width: 135px !important;
  border-radius: 1.5em !important;
  font-size: 1em !important;
  font-family: 'Montserrat';
  background-color: #53566D !important;
  margin-top: 50px;
}


/*----------------ACTIVITIES-------------*/
.title-services {
  color: #778270 !important;
  font-family: 'Peace' !important;
  font-size: 3em !important;
  text-shadow: 5px 5px #B9C6B9 !important;
  margin-left: 8%;
}

.title-playground {
  color: #53566D !important;
  font-family: 'Peace' !important;
  font-size: 2em !important;
  text-shadow: 2px 2px #C0C9D1 !important;
}


.text-playground {
  font-family: 'Montserrat_reg';
  font-size: 1em;
}

.title-anniversaires {
  color: #53566D !important;
  font-family: 'Peace' !important;
  font-size: 2em !important;
  text-shadow: 2px 2px #C0C9D1 !important;
  text-align: end;
}

.text-anniversaires {
  font-family: 'Montserrat_reg';
  font-size: 1em;
  text-align: end;
}

.button-concept {
  width: 120px !important;
  border-radius: 1.5em !important;
  font-size: 1em !important;
  font-family: 'Montserrat';
  background-color: #53566D !important
}

/*----------------COFFEE-------------*/
.button-coffee {
  width: 140px !important;
  border-radius: 1.5em !important;
  font-size: 1em !important;
  font-family: 'Montserrat';
  background-color: #53566D !important
}

/*----------------GALLERY-------------*/
.title-gallery {
  color: #778270 !important;
  font-family: 'Peace' !important;
  font-size: 3em !important;
  text-shadow: 5px 5px #B9C6B9 !important;
}

.button-gallery {
  width: 150px !important;
  border-radius: 1.5em !important;
  font-size: 1.1em !important;
  font-family: 'Montserrat';
  background-color: #53566D !important;
}

/*----------------FindUs-------------*/

.title-FindUs {
  color: #778270 !important;
  font-family: 'Peace' !important;
  font-size: 3em !important;
  text-shadow: 5px 5px #B9C6B9 !important;
}

.text-findus{
  display: flex !important;
  flex-direction: row !important;
  font-size: 0.9em;
  margin-bottom:10px
}

.icon{
  padding-left: 20px !important;
}

/*----------------Link-Bday-------------*/
.bday{
  width:100%;

}
.birthday{
  background-image: url("images/cake.webp");
  background-attachment: fixed;
  background-size: cover;
  height:50vh;
}

.cardImgOverlay{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Montserrat_reg';
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.9em;
}
/*----------------TOPSCREEN-------------*/

.linkDisplay {
  font-family: "Meltix_Regular";
  font-size: 0.9em;
}

.NavLink:hover {
  text-decoration: underline;
}

.fb {
  width: 30px;
  color: #202A45
}

.ig {
  width: 25px;
  color: #202A45;
}

/*--------------BOTTOMSCREEN------------*/


.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
  font-size: 0.9em;
  font-family: 'Meltix_Regular';
}

.logoSize {
  margin-bottom: 15px;
  width: 165px;
  height: 165px;
}

.smallLogoSize {
  width: 15%;
  align-self: center;
  display: flex;
  align-items: space-around;
}

/*--------------ACCUEILSCREEN------------*/

.button:hover {
  font-weight: bold;
}

.partThree {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.imgbday {
  width: 400px;
  height: 400px;
}

.partThree2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleAccueilTwo {
  text-align: center;
  font-family: 'Meltix_Bold';
  color: #BF6A4D;
}

.titleAccueilThree {
  text-align: center;
  font-family: 'Meltix_Bold';
  font-size: 4em;
  color: #86aa92;
}

.desc1 {
  margin-left: 220px;
  margin-top: 25px;
  position: relative;
  background-color: white;
  width: 200px;
  height: 250px;
  border-radius: 10%;
  border: 4px #86aa92 dashed;
}

.desc3 {
  margin-left: 220px;
  margin-top: 25px;
  position: relative;
  background-color: white;
  width: 200px;
  height: 250px;
  border-radius: 10%;
  border: 4px #f7dfdf dashed;
}

.icecreamtruck {
  width: 300px;
  height: 300px;
  border-radius: 10%;
  margin-right: 80px;
  position: absolute;
  border: 2px #f7dfdf solid;
}

.imgbday {
  width: 300px;
  height: 300px;
  border-radius: 10%;
  margin-right: 80px;
  position: absolute;
  border: 2px #86aa92 solid;
}

.desc4 {
  position: relative;
  margin-top: 25px;
  margin-left: 220px;
  background-color: white;
  width: 200px;
  height: 250px;
  border-radius: 10%;
  border: 4px #c8cccc dashed;
}

.food {
  width: 300px;
  height: 300px;
  border-radius: 10%;
  margin-right: 80px;
  position: absolute;
  border: 2px #c8cccc solid;
}

.desc2 {
  position: relative;
  margin-top: 25px;
  margin-left: 220px;
  background-color: white;
  width: 200px;
  height: 250px;
  border-radius: 10%;
  border: 4px #202A45 dashed;
}

.truck {
  width: 300px;
  height: 300px;
  border-radius: 10%;
  margin-right: 80px;
  position: absolute;
  border: 2px #202A45 solid;
}

.receptionText {
  font-family: 'Dosis';
  font-size: 1.4em;
  width: 600px;
  height: 200px;
  text-align: center;
  background-color: #90caf9;
  border: 4px #5d99c6 dashed;
  border-radius: 10px;
  padding: 45px 55px 15px 55px;
}

.partFive {
  height: 800px;
  background: #E3F2FD;
}

.photo {
  height: 360px;
}

.slides {
  padding-left: 170px;
}

.subscribe {
  background: url('./images/subscribe_bg.png');
  background-size: 100%;
  height: 400px;
}